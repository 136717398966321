import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink, navigate } from 'gatsby';

import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Input,
  Link,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import api from '../providers/api';
import Layout from '../components/layout';
import { login, userAuthenticated } from '../providers/auth';

function Login({ location }) {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useBoolean();
  const toast = useToast();

  useEffect(() => {
    if (userAuthenticated()) navigate('/admin');
  });

  const handleSignIn = async (event) => {
    event.preventDefault();
    if (!email || !senha) {
      toast({
        description: 'Preencha todos os campos!',
        isClosable: true,
        status: 'error',
        title: 'Erro ao realizar login',
      });
    } else {
      setLoading.on();
      api.post('/auth', { email, senha })
        .then((result) => {
          login(result.data);
          navigate('/admin');
        })
        .catch((err) => {
          const status = err.response ? err.response.data.error : 'Erro desconhecido';
          toast({
            description: status,
            isClosable: true,
            status: 'error',
            title: 'Erro ao realizar o login',
          });
          setLoading.off();
        });
    }
  };

  return (
    <Layout location={location.pathname}>
      <Container>
        <Center mt={5}>
          <StaticImage src="../assets/icon.svg" alt="OCI" width={128} />
        </Center>
        <Box as="form" onSubmit={handleSignIn}>
          <FormControl isRequired>
            <FormLabel>Endereço de E-Mail</FormLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Mail"
              type="email"
              value={email}
            />
          </FormControl>
          <FormControl isRequired my={2}>
            <FormLabel>Senha</FormLabel>
            <Input
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Senha"
              type="password"
              value={senha}
            />
          </FormControl>
          <Button
            colorScheme="orange"
            float="right"
            isLoading={loading}
            loadingText="Autenticando..."
            mt={4}
            type="submit"
          >
            Entrar
          </Button>
        </Box>
        <Center w="100%">
          <Link as={GatsbyLink} to="/cadastro">Não possuo cadastro</Link>
        </Center>
      </Container>
    </Layout>
  );
}

Login.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Login;
